import React from 'react'
import styled from 'styled-components/macro'
import { Drag } from './animation'
import Image from './image'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

const ImageStyled = styled(Image)`
  width: 100px;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`

const Header = () => (
  <Wrapper>
    <Drag>
      <div style={{ maxWidth: '200px' }}>
        <ImageStyled />
      </div>
    </Drag>
  </Wrapper>
)

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
