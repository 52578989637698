import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Resume from '../components/Resume'
library.add(fab)

const Wrapper = styled.div``

const Center = styled.div`
  text-align: center;
  margin: 0 1em 3em;
  h1 {
    font-family: 'Permanent Marker', cursive;
  }
`

const SocialLink = styled.a`
  color: #ffd700;
  margin: 10px;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: #000;
  }
  svg {
    max-width: 40px;
  }
`

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`frontend`, `developer`, `web`, `portfolio`]}
    />
    <Wrapper>
      <Center>
        <h1>Hi, I'm Prakash Gurung</h1>
        <h1>A FRONTEND Developer</h1>
      </Center>
      <Center>
        <SocialLink href="https://www.linkedin.com/in/gurungprakash/">
          <FontAwesomeIcon
            size="2x"
            icon={['fab', 'linkedin']}
            style={{ width: '40px' }}
          />
        </SocialLink>
        <SocialLink href="https://github.com/prakash118">
          <FontAwesomeIcon
            size="2x"
            icon={['fab', 'github-square']}
            style={{ width: '40px' }}
          />
        </SocialLink>
      </Center>
      <Center>
        <Resume />
      </Center>
    </Wrapper>
  </Layout>
)

export default IndexPage
