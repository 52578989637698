import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

const Download = styled.a`
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.4s ease-in-out;
  &:hover {
    color: #ffd700;
  }
`

const Resume = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "prakashgurung.pdf" }) {
          publicURL
        }
      }
    `}
    render={data => (
      <Download className={className} href={data.file.publicURL} download>
        <FontAwesomeIcon
          size="2x"
          icon={faFileDownload}
          style={{ width: '40px' }}
        />
        Resume
      </Download>
    )}
  />
)
export default Resume
